import React, { Component } from "react";
import { Helmet } from "react-helmet";

class Kontak extends Component {
  render() {
    return (
      <div>
        <Helmet>
            <title>Kontak PASS Engineering</title>
            <meta name="description" content="Kontak untuk pemesanan produk hidrogen dari PASS Engineering." />
            <meta property="og:title" content="Kontak PASS Engineering" />
            <meta property="og:url" content="https://hidrogen.id/kontak" />
            <meta property="og:image" content="https://hidrogen.id/logo192.png" />
        </Helmet>
        <div className="content-page">
          <h3>PASS Engineering - Kontak</h3>
          {/* <p>If you have any questions, please feel free to contact us at passakti@yahoo.com or +62 812 8888 845.</p> */}
          <p>Jangan ragu untuk menghubungi kami jika ada pertanyaan. Kami selalu dengan senang hati membantu!</p>
          <h4>Detil Kontak:</h4>
          <ul>
          <li><strong>Email:</strong> passakti@yahoo.com</li>
          <li><strong>HP:</strong> +62 812 8888 845</li>
          <li><strong>Alamat:</strong> Jl. Raya Narogong KM. 11 No. 8/9, Limus Nunggal, Kec. Cileungsi, Kabupaten Bogor, Jawa Barat 16820</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Kontak;