import React, { Component } from "react";
import { Route, NavLink, Routes, HashRouter } from "react-router-dom";

import logo from './hidrogen.png';
import pass from './pass-engineering.png';

import Home from "./Home";
import Pemesanan from "./Pemesanan";
import Kontak from "./Kontak";

class App extends Component {
render() {
  return (
    <HashRouter>
      <div className="App">
        {/* <h1>Hidrogen</h1> */}
        <div className="logo-row">
          <img src={logo} alt="Hidrogen" title="Hidrogen" className="logo" />
        </div>
        <ul className="header">
          <li><NavLink to="/">Beranda</NavLink></li>
          <li><NavLink to="/pemesanan">Pemesanan</NavLink></li>
          <li><NavLink to="/kontak">Kontak</NavLink></li>
        </ul>
        <div className="content">
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/pemesanan" element={<Pemesanan />}></Route>
            <Route exact path="/kontak" element={<Kontak />}></Route>
          </Routes>
        </div>
      </div>
      <div className="footer">
        <img src={pass} alt="PASS Engineering" title="PASS Engineering" className="pass" />
      </div>
    </HashRouter>
  );
}
}
export default App;