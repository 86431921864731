import React, { Component } from "react";
import { Helmet } from "react-helmet";

import h2 from './h2.png';
import fwh from './fuel-water-hybrid.png';
import hidrofit from './hidrofit.png';
import pasta from './hidrogen-pasta.png';
// import rudy from './rudy-sumardi.jpg';

class Home extends Component {
  render() {
    return (
      <div className="content">
        <Helmet>
            <title>Hidrogen | Produk Hidrogen</title>
            <meta name="description" content="Produk hidrogen yang telah dikembangkan Fuel Water Hybrid, Hidrogen PASTA, dan Hidrofit." />
            <meta property="og:title" content="Hidrogen | Produk Hidrogen" />
            <meta property="og:url" content="https://hidrogen.id/" />
            <meta property="og:image" content="https://hidrogen.id/logo192.png" />
        </Helmet>
        <div className="content-blockquote">
            <blockquote>
                <p>
                    Air kaya hidrogen tidak hanya memiliki ribuan testimoni, tapi merupakan SATU - SATUNYA produk kesehatan yang didukung ratusan jurnal kedokteran terkemuka di dunia. Diteliti lebih dari 1600 peneliti terkemuka, lebih dari 1000 artikel ilmiah di bidang kesehatan, dapat menyembuhkan lebih dari 170 penyakit pada organ tubuh manusia, dan terbukti aman dikonsumsi oleh semua kalangan lebih dari 50 tahun.
                </p>
                <cite>- Molecular Hydrogen Institute</cite>
            </blockquote>
        </div>

        <div className="video">
            <iframe title="YouTube" id="player" width="100%" src="https://www.youtube.com/embed/KvY8W8gJgYM" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture">Your browser does not support this, try viewing it on YouTube: https://www.youtube.com/embed/KvY8W8gJgYM
            </iframe>
        </div>

        <div className="rudy"></div>
        {/* <img src={rudy} alt="Rudy Sumardi" title="Rudy Sumardi" className="rudy-img" /> */}

        <div className="content-page">
        <h1>HIDROGEN</h1>
            <div className="container">
                <div className="left">
                <img src={h2} alt="H2" title="H2" className="H2" />
                </div>
                <div className="right">
                    <p>
                        Minum air kaya hidrogen adalah bagian dari pola hidup sehat seperti DIET SEIMBANG, OLAHRAGA RUTIN, dan MINUM OBAT / SUPLEMEN teratur. Air hidrogen TIDAK MENGGANTIKAN, namun SALING MELENGKAPI dengan cara hidup sehat yang lain. Setiap orang, dalam hubungannya dengan AIR yang MEMANG HARUS DIMINUM SETIAP HARI hanya memiliki dua pilihan, MINUM AIR MINERAL BIASA atau MINUM AIR HIDROGEN. Air kaya hidrogen jauh lebih baik dibanding air biasa, bermanfaat pada lebih dari 170 penyakit, dengan manfaat utama untuk PROGRAM PENCEGAHAN PENYAKIT bagi SELURUH KELUARGA dalam jangka panjang, bankan SEUMUR HIDUP.
                    </p>
                </div>
            </div>
            
            <h2>ANTI OKSIDAN <br/><strong>TERBAIK!</strong></h2>
            <ol>
                <li>Bersifat selektif (hanya aktif apabila radikal bebas berbahaya)</li>
                <li>Mengaktifkan sistem anti oksidan endogen & eksogen</li>
                <li>Hiporeaktif & tidak berpengarh buruk bagi tubuh</li>
                <li>Toksitas rendah & ditoleransi tubuh lebih baik</li>
                <li>Ukuran sangat kecil, lebih cepat & mudah menembus sel</li>
                <li>Memiliki manfaat lain selain sebagai anti oksidan</li>
                <li>Dapat diiproduksi secara mandiri (lebih praktis, ekonomis)</li>
            </ol>
            
            <h2>Produk yang telah dikembangkan</h2>
            <div class="accordion">
                <details>
                    <summary><h3>Fuel Water Hybrid</h3></summary>
                    <div>
                        <center><img src={fwh} alt="Fuel Water Hybrid" title="Fuel Water Hybrid" className="logo-img" /></center>
                        <p>
                            Sebuah efisiensi pemakaian bahan bakar kendaraan bensin maupun solar dengan berbasis hidrogen sehingga manfaat yang didapat adalah kendaraan berperformance lebih meningkat, efisiensi bahan bakar, dan jaminan lulus uji emisi gas buang dengan oktan 130.
                        </p>
                    </div>
                </details>
                <details>
                    <summary><h3>HidrogenPASTA</h3></summary>
                    <div>
                        <center><img src={pasta} alt="Hidrogen PASTA" title="Hidrogen PASTA" className="logo-img" /></center>
                        <p>
                            Sebuah rekayasa teknologi yang berbasis hidrogen untuk menekan pemakaian beban rekening pembayaran listrik industri dengan satuan panel RST dan berbasis manajemen distribusi panel (MDP). Sehingga listrik yang terpakai dari beban ke meteran itu bukan mencuri atau menjadi efisiensi hingga 50%.
                        </p>
                    </div>
                </details>
                <details>
                    <summary><h3>Hidrofit</h3></summary>
                    <div>
                        <center><img src={hidrofit} alt="Hidrofit Plus" title="Hidrofit Plus" className="logo-img" /></center>
                        <p>
                            Adalah hidrogen untuk kesehatan, seperti kita ketahui hidrogen adalah anti oksidan terbaik selain vitamin, buah-buahan dan sayuran. Dikarenakan vitamin, buah-buahan dan sayuran harus diolah terlebih dahulu melalui tubuh, berbeda dengan hidrogen langsung dicerna melalui air hidrogen yang terbentuk dari pelarut kandungan terdalam hidrogen.
                        </p>
                        <ol>
                            <li><strong>Terapi Uap</strong> untuk kesehatan.</li>
                            <li><strong>Terapi Mandi</strong> dilakukan pada bilasan mandi terakhir tujuannya untuk regenerasi kulit dan melawan detox (penuaan), terutama untuk yang rambutnya botak.</li>
                            <li><strong>Terapi Minum</strong> dengan alat hidrofit terbaru, hidrogen yang diminum menjadi anti oksidan terbaik dapat menyembuhkan 170 penyakit. telah diurai oleh para dokter dan dukungan jurnal kedokteran seluruh dunia.</li>
                        </ol>
                    </div>
                </details>
            </div>
            {/* <ol>
                <li>
                <strong>Fuel Water Hybrid</strong><br/>
                <img src={fwh} alt="Fuel Water Hybrid" title="Fuel Water Hybrid" className="logo-img" /><br/>
                Sebuah efisiensi pemakaian bahan bakar kendaraan bensin maupun solar dengan berbasis hidrogen sehingga manfaat yang didapat adalah kendaraan 
                berperformance lebih meningkat, efisiensi bahan bakar, dan jaminan lulus uji emisi gas buang dengan oktan 130.
                </li>
                <li>
                <strong>Hidrogen PASTA</strong><br/>
                <img src={pasta} alt="Hidrogen PASTA" title="Hidrogen PASTA" className="logo-img" /><br/>
                Sebuah rekayasa teknologi yang berbasis hidrogen untuk menekan pemakaian beban rekening pembayaran listrik industri dengan satuan panel RST dan berbasis manajemen distribusi panel (MDP). 
                Sehingga listrik yang terpakai dari beban ke meteran itu bukan mencuri atau menjadi efisiensi hingga 50%.
                </li>
                <li>
                <strong>Hidrofit</strong><br/>
                <img src={hidrofit} alt="Hidrofit Plus" title="Hidrofit Plus" className="logo-img" /><br/>
                Adalah hidrogen untuk kesehatan, seperti kita ketahui hidrogen adalah anti oksidan terbaik selain vitamin, buah-buahan dan sayuran. Dikarenakan vitamin, buah-buahan dan sayuran harus diolah terlebih dahulu melalui tubuh, berbeda dengan hidrogen langsung dicerna 
                melalui air hidrogen yang terbentuk dari pelarut kandungan terdalam hidrogen.<br/>
                1. <strong>Terapi Uap</strong> untuk kesehatan.<br/>
                2. <strong>Terapi Mandi</strong> dilakukan pada bilasan mandi terakhir tujuannya untuk regenerasi kulit dan melawan detox (penuaan), terutama untuk yang rambutnya botak.<br/>
                3. <strong>Terapi Minum</strong> dengan alat hidrofit terbaru, hidrogen yang diminum menjadi anti oksidan terbaik dapat menyembuhkan 170 penyakit. telah diurai oleh para dokter dan dukungan jurnal kedokteran seluruh dunia.
                </li>
            </ol> */}

            <h2>Jaminan</h2>
            <div className="pasta"><div className="module">
            1 JAM setelah sistem terpasang<br/>HEMAT LISTRIK<br/>Langsung MINIMAL 10%<br/>Di meteran / live di HP<br/>Sebelum meteran tercetak di akhir bulan<br/>JAMINAN UANG KEMBALI 100%<br/>JIKA TIDAK TERBUKTI HEMAT
            </div></div>
            {/* <div className="container2">
                <div className="left">
                <img src={oil} alt="H2" title="Hidrofit" className="H21" />
                </div>
                <div className="right">
                    <p>
                        <ol>
                            <li>Bersifat selektif (hanya aktif apabila radikal bebas berbahaya)</li>
                            <li>Mengaktifkan sistem antioksidan endogen & eksogen</li>
                            <li>Hiporeaktif & tidak berpengarh buruk bagi tubuh</li>
                            <li>Toksitas rendah & ditoleransi tubuh lebih baik</li>
                            <li>Ukuran sangat kecil, lebih cepat & mudah menembus sel</li>
                            <li>Memiliki manfaat lain selain sebagai antioksidan</li>
                            <li>Dapat diiproduksi secara mandiri (lebih praktis, ekonomis)</li>
                        </ol>
                    </p>
                </div>
            </div> */}

        </div>
        
      </div>
    );
  }
}

export default Home;