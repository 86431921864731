import React, { Component } from "react";
import { Helmet } from "react-helmet";

class Pemesanan extends Component {
  render() {
    return (
      <div>
        <Helmet>
            <title>Pemesanan Produk Hidrogen</title>
            <meta name="description" content="Pemesanan produk hidrogen berupa Fuel Water Hybrid, Hidrogen PASTA, dan Hidrofit." />
            <meta property="og:title" content="Pemesanan Produk Hidrogen" />
            <meta property="og:url" content="https://hidrogen.id/pemesanan" />
            <meta property="og:image" content="https://hidrogen.id/logo192.png" />
        </Helmet>
        <div className="video">
              <iframe title="YouTube" id="player" width="100%" src="https://www.youtube.com/embed/bsTouxX3-P4" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture">Your browser does not support this, try viewing it on YouTube: https://www.youtube.com/embed/bsTouxX3-P4
            </iframe>
        </div>
        <div className="content-page">
          <h2>Fuel Water Hybrid</h2>
          <p>Sebuah alat reaktor yang menghasilkan GAS HIDROGEN masuk ke ruang bakar yang berfungsi menambah oktan bahan bakar bersumber dari AIR MURNI menjadi oktan 130 yang sangat BESAR hingga power bertambah 2 kali lipat. Dan Pastinya! BBM hemat lebih dari 30%. Asap buangan menjadi 0 atau TANPA EMISI (sesuai program pemerintah kedepan). Alat ini di pasang di belakang mobil dan tidak merubah atau mengintervensi mesin mobil (bensin atau diesel). Mesin dingin dan sangat aman karena HIDROGEN GAS yang bersumber dari air.</p>
          <h3>Biaya Pembuatan Alat Reaktor</h3>
          <h4>Motor dan Mobil</h4>
          <ol>
            <li>100-250 CC Rp3,5 juta</li>
            <li>1.000 - 2.500 CC Rp7,5 juta</li>
            <li>3.000 CC up Rp15 juta</li>
            <li>3.000 - 5.000 Rp18 juta</li>
            <li>Alat berat Rp35 juta</li>
            <li>Multi Rp12,5 juta</li>
          </ol>
          <p>Reaktor khusus untuk di mobil sekelas FORTUNER, dan juga berfungsi untuk alat terapi kesehatan.</p>
          <h2>HIDROFIT Plus: Rp15 juta</h2>
          <p>Alat terapi kesehatan keluarga berfungsi untuk terapi dihisap, diminum dan untuk mandi hidrogen.</p>
          <p>TURBO Rp15,5 juta untuk pemakaian mulai 1.000 - 3.500 CC plus terapi kesehatan keluarga.</p>
          <h2>Pemesanan</h2>
          <h3>Proses</h3>
          <p>Pembuatan Alat Reaktor dengan DP 50% dan PELUNASAN dilakukan saat akan pemasangan/pengiriman. Proses pabrikasi 10 hari kerja dengan instalasi pemasangan 1 jam (biaya sudah termasuk pemasangan).</p>
          <p>BONUS BANTU JUAL 10 unit, GRATIS 1 unit.</p>
        </div>
      </div>
    );
  }
}

export default Pemesanan;